import { createWebHistory, createWebHashHistory, createRouter } from "vue-router";

const DownloadFile = () => import("@/components/Teras/Download");

const Home = () => import("@/components/Home");
const ChiSiamo = () => import("@/components/Chi-siamo");
const RisorseEducative = () => import("@/components/Risorse-educative");
const Certificazione = () => import("@/components/Certificazione");
const LaboratoriCertificati = () => import("@/components/Laboratori-certificati");

const EnteLogin = () => import("@/components/Ente/Login");
const EnteResetPassword = () => import("@/components/Ente/Reset-password");
const EnteRegistrazione = () => import("@/components/Ente/Registrazione");
const EnteHome = () => import("@/components/Ente/Home");
const EnteProfilo = () => import("@/components/Ente/Profilo");

const EnteLaboratorioProfilo = () => import("@/components/Laboratorio/Profilo");
const EnteLaboratorioChecklist = () => import("@/components/Laboratorio/Checklist-compilazione");
const EnteLaboratorioRisultatoChecklist  = () => import("@/components/Laboratorio/Checklist-risultato");
const EnteLaboratorioPagamentoCertificazione = () => import("@/components/Laboratorio/Certificato-pagamento");

const TaskForceAccettaChecklist = () => import("@/components/Task-force/Accetta-checklist");

var mode = (process.env.NODE_ENV != "development") ? createWebHistory("/") : createWebHashHistory("/")

export const createVueRouter = (app) => {
   const router = createRouter({
      history: mode,

      scrollBehavior (to, from, savedPosition) {
         document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      },

      routes: [
         { path: "/dwn/:file", name: "download-file", meta: { requiresEvent: false, requiresAuth: false }, components:{main: DownloadFile }},

         { path: "/", name: "root", meta: { requiresEvent: false, requiresAuth: false }, components:{main: Home }},
         { path: "/who-we-are", name: "chi-siamo", meta: { requiresEvent: false, requiresAuth: false }, components:{main: ChiSiamo }},
         { path: "/educational-resources", name: "risorse-educational", meta: { requiresEvent: false, requiresAuth: false }, components:{main: RisorseEducative }},
         { path: "/certification", name: "certificazione", meta: { requiresEvent: false, requiresAuth: false }, components:{main: Certificazione }},
         { path: "/laboratories-certified", name: "laboratories-certified", meta: { requiresEvent: false, requiresAuth: false }, components:{main: LaboratoriCertificati }},

         { path: "/login", name: "login", meta: { requiresEvent: false, requiresAuth: false }, components:{main: EnteLogin }},
         { path: "/reset-password", name: "reset-password", meta: { requiresEvent: false, requiresAuth: false }, components:{main: EnteResetPassword }},
         { path: "/reset-password/:action", name: "reset-password-action", meta: { requiresEvent: false, requiresAuth: false }, components:{main: EnteResetPassword }},
         { path: "/new-account", name: "new-account", meta: { requiresEvent: false, requiresAuth: false }, components:{main: EnteRegistrazione }},
         
         { path: "/reserved-area/institution", name: "ente-home", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteHome }},
         { path: "/reserved-area/institution/profile", name: "ente-profilo", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteProfilo }},

         { path: "/reserved-area/institution/new-laboratory", name: "entelaboratorio-nuovo", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteLaboratorioProfilo }},
         { path: "/reserved-area/institution/laboratory/:id", name: "entelaboratorio-profilo", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteLaboratorioProfilo }},
         
         { path: "/reserved-area/institution/laboratory/:laboratorio/new-application", name: "entelaboratorio-nuovacertificazione", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteLaboratorioChecklist }},
         { path: "/reserved-area/institution/laboratory/:laboratorio/retry-application", name: "entelaboratorio-riprovacertificazione", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteLaboratorioChecklist }},
         { path: "/reserved-area/institution/laboratory/:laboratorio/renew-application", name: "entelaboratorio-rinnovocertificazione", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteLaboratorioChecklist }},
         { path: "/reserved-area/institution/laboratory/:laboratorio/retry-renew-application", name: "entelaboratorio-riprovarinnovocertificazione", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteLaboratorioChecklist }},
         
         { path: "/reserved-area/institution/laboratory/:laboratorio/application-result/:checklist", name: "entelaboratorio-risultatochecklist", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteLaboratorioRisultatoChecklist }},
         { path: "/reserved-area/institution/laboratory/:laboratorio/application-payment/:checklist", name: "entelaboratorio-pagamentocertificazione", meta: { requiresEvent: false, requiresAuth: true }, components:{main: EnteLaboratorioPagamentoCertificazione }},

         { path: "/eflm-taskforce/endorse-application/:checklist", name: "taskforce-accettachecklist", meta: { requiresEvent: false, requiresAuth: false }, components:{main: TaskForceAccettaChecklist }},

         { path: "/:catchAll(.*)", redirect: "/" } 
      ]
   })

   router.beforeEach(async (to, from, next) => {
      var teras = app.config.globalProperties.$teras
      let error = false
      teras.app.drawer = false
      //to.query contiene le variabili passate in modo standard es. ?variabile=valore
      //to.params contiene le variabili passate in vuejs passate in base a routes[]
      console.log("\nbeforeEach \ncheckFirst: "+router.checkFirst+"\nfrom: "+from.path+"\nto: "+to.path+"\n\n")
      teras.router.from = from.path
      teras.router.to = to.path

      if (!teras.check.first){
         error= true
         console.log("SONO RICHIESTE LE OPERAZIONI DI PRIMO AVVIO")
         teras.event.emit("checkFirst", from, to)
      }
      
      /*console.log("AUTH "+teras.app.auth +" AND error "+error)
      if (!error && to.matched.some(record => record.meta.requiresAuth) && !teras.check.auth){
         console.log("È RICHIESTO UTENTE E NON È ANCORA VERIFICATO")
         teras.event.emit("checkAuth", from, to)
         error = true
      }*/
    
      if(error){
         next(false)
      }else{
         console.log("beforeEach next")
         //window.scrollTo(0,0)
         teras.app.ready = true
         next()
         teras.event.emit("routerChange", to)
      }
   })

   return router
}