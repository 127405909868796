import main from './main'

export class teras extends main{

   constructor (args) {
      super(args)
      this.name = 'greenlabs.eflm.eu'
      this.version = '1.0.1'
      this.urlsite = (process.env.NODE_ENV != 'development') ? document.location.origin + '/' : ''
      this.page = {
         title: 'Home'
      }
      this.pagename= ""

      this.apiUrl = 'https://api.terasmm.it/201806/'
      this.mysql = {
         composer:'db',
         adapter:'contabo01',
         database:'eflm_greenlabs'
      }
      this.minWidthMobile= 760

      this.stripe= {
         /*key: "pk_test_T4RtRGcixALXeLALc6oGbrzG",
         keyMode: "deTest"*/

         key: "pk_live_pyMXz2XPpjAPyy0MutnrhxIe",
         keyMode: "deReal"
      }
      this.defaultConvertOpt= { compact: true, ignoreComment: true, spaces: 3 }
      this.pageTitleBase= "EFLM Green & Sustainable Laboratory"

      this.googleRecaptchaSw= "greenlabs.eflm.eu"
      this.googleRecaptchaKey= "6LeGH2QlAAAAAF3dBZTcT_jRFncWItgBqoZ0BwpP"
      
      super.init()
      return this
   }

 
}
export default new teras
