import basic from "@/models/basic"
import dbItem  from "@/teras/dbItem"
import convert from 'xml-js'
import Email from "@/components/Email"

class laboratorio extends basic{
   constructor (args) {
      super(args)

      this.numeroGiorniPerRimandareChecklist= 90
      this.mesiValiditaCertificato= 24

      //Array per la select del profilo del laboratorio per la domanda sul numero di test effettuati dal laboratorio (caricato nel checkFirst)
      this._numerotesteffettuati= []
      //Array per la select del profile del laboratorio per la domanda sul tipo di laboratorio (caricato nel checkFirst)
      this._tipi= []
        
      this.profile = new dbItem({table: "entelaboratorio"})
      //Elenco certificati del laboratorio
      this.certificati= []

      return this
   }

   async new(){
      await super.new()
   }
   async load(id){
      await super.load(id)
      await this.loadCertificati()
   }
   async loadEncode(id){
      await super.loadEncode(id)
      await this.loadCertificati()
   }
   async loadCertificati(){
      await this.axios.query("fetchAll", 
         `SELECT lab_cert.id, DATE_FORMAT(lab_cert.emessoil, "%Y-%m-%d") AS emessoil_display, 
            CASE
               WHEN IFNULL(fiscale.id, 0)<>0 AND DATE(NOW())>lab_cert.scadenza THEN 'expired'
               WHEN IFNULL(fiscale.id, 0)<>0 AND DATE(NOW())<=lab_cert.scadenza THEN 'active'
               WHEN IFNULL(fiscale.id, 0)= 0 THEN 'to-pay'
            END AS stato
         FROM entelaboratorio_certificato AS lab_cert LEFT JOIN fiscale ON (lab_cert.id= fiscale.areaid AND fiscale.area=1)
         WHERE lab_cert.id>0 AND lab_cert.entelaboratorio= ←lab
         ORDER BY lab_cert.emessoil DESC`, {lab: this.profile.row.id}, this, "certificati")
   }

   async save(){
      if(this.profile.row.id==""){
         this.profile.row.creatoil= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      } 
      await super.save()
   }

   /*async haCertificazioneInBozza(tipoCertificazione){
      var valoreTipoCertificazione= 0
      switch(tipoCertificazione){
         case "nuova":
            valoreTipoCertificazione= 3
            break;
         case "rinnovo":
            valoreTipoCertificazione= 6
            break;
      }

      var checkBozza= await this.axios.query("fetchRow", 
         `SELECT lab_cert.id 
          FROM entelaboratorio_checklist AS lab_cert LEFT JOIN _checklist AS cert ON lab_cert.checklist= cert.id 
          WHERE lab_cert.id>0 AND lab_cert.entelaboratorio= ←lab AND cert.attivo= 1 AND lab_cert.bozza= 1 AND cert.tipo= ←tipocert`, {lab: this.profile.row.id, tipocert: valoreTipoCertificazione})
      
      return checkBozza.result.id!=undefined ? checkBozza.result.id : 0
   }*/
   haCertificatoAttivo(){
      for(var certificato of this.certificati){
         if(certificato.stato=='active'){
            return true;
         }
      }
      return false;
   }
   async haChecklistNonSuperata(id, isRinnovo){
      var conds= []
      if(isRinnovo){
         conds.push("_checklist.tipo= 6")
      } else{
         conds.push("_checklist.tipo= 3")
      }

      conds= conds.length!=0 ? " AND "+conds.join(" AND ") : ""
      var check= await this.axios.query("fetchRow", 
         `SELECT lab_checkl.id
         FROM entelaboratorio_checklist AS lab_checkl LEFT JOIN _checklist ON lab_checkl.checklist= _checklist.id
         WHERE lab_checkl.id>0 AND lab_checkl.entelaboratorio= ←lab AND lab_checkl.esito= -1 AND DATE_ADD(lab_checkl.inviatoil, INTERVAL ←giorni DAY)>NOW()`+conds, {lab: id==undefined ? this.profile.row.id : id, giorni: this.numeroGiorniPerRimandareChecklist})

      return check.result.id!=undefined
   }
   async haChecklistInBozza(idChecklist, idLab){
      var checkBozza= await this.axios.query("fetchRow", 
         `SELECT id 
          FROM entelaboratorio_checklist
          WHERE id>0 AND entelaboratorio= ←lab AND bozza= 1 AND checklist= ←chk`, {lab: idLab==undefined ? this.profile.row.id : idLab, chk: idChecklist})
      
      return checkBozza.result.id!=undefined ? checkBozza.result.id : 0
   }
   async recuperaUltimoTentativoNonSuperato(idLab, isRinnovo){
      var conds= []
      if(isRinnovo){
         conds.push("_checklist.tipo= 6")
      } else{
         conds.push("_checklist.tipo= 3")
      }

      conds= conds.length!=0 ? " AND "+conds.join(" AND ") : ""
      var tentativo= await this.axios.query("fetchRow", 
         `SELECT lab_checkl.*
         FROM entelaboratorio_checklist AS lab_checkl LEFT JOIN _checklist ON lab_checkl.checklist= _checklist.id
         WHERE lab_checkl.id>0 AND lab_checkl.entelaboratorio= ←lab AND lab_checkl.esito=-1`+conds+` 
         ORDER BY lab_checkl.inviatoil DESC`, {lab: idLab==undefined ? this.profile.row.id : idLab})
      
      return tentativo.result
   }

   async generaCertificato(idCertificato){
      var certificatoDaGenerare= await this.axios.query("fetchRow", 
         `SELECT cert.id AS certificato_id, lab.nome AS laboratorio_nome, lab.citta AS laboratorio_citta, lab.nazione AS laboratorio_nazione,
            ente.nome AS ente_nome, ente.citta AS ente_citta, ente.nazione AS ente_nazione,
            DATE_FORMAT(cert.emessoil, "%d/%m/%Y") AS certificato_emessoil_display, DATE_FORMAT(cert.scadenza, "%d/%m/%Y") AS certificato_scadenza_display
          FROM entelaboratorio_certificato AS cert LEFT JOIN entelaboratorio AS lab ON cert.entelaboratorio= lab.id LEFT JOIN ente ON lab.ente= ente.id
          WHERE cert.id= ←cert`, {cert: idCertificato})

      var xml = convert.js2xml({ certificato: certificatoDaGenerare.result }, this.teras.defaultConvertOpt)
      console.log(xml)
      var response= await this.axios.post(this.teras.apiUrl+"vue/pdf", {
         fopVersion: "2.8",
         pdf: this.calcolaPercorsoCertificato(certificatoDaGenerare.result.certificato_id, "locale"),
         xsl: "/www/greenlabs.eflm.eu/template/certificato.xsl",
         xml: xml
      })
      if(!response.data.data.esitogenerazione){
         var testoEmailErrore= "An error is occured while generating the certificate "+idCertificato+", try to execute the fop command.<br><ul>"
         for(var log of response.data.data.log){
            testoEmailErrore+= "<li>"+log+"</li>"
         }
         testoEmailErrore+= "</ul>"
         this.axios.email({ to: "mmm@terasmm.it", from: "eflm@eflm.eu", oggetto: "[EFLM Green Labs] Error genereting certificate PDF", body: testoEmailErrore })
         return { esito: false }
      }

      return { esito: true, url: this.calcolaPercorsoCertificato(certificatoDaGenerare.result.certificato_id, "pubblico") }
   }

   async inviaMailConfermaOrdine(idCertificato){
      var certificato= await this.axios.query("fetchRow", 
         `SELECT cert.id, lab.id AS laboratorio_id, lab.nome AS laboratorio_nome, ente.id AS ente_id, ente.nome AS ente_nome, ente.responsabile_nome, ente.responsabile_cognome, ente.responsabile_email
          FROM entelaboratorio_certificato AS cert LEFT JOIN entelaboratorio AS lab ON cert.entelaboratorio= lab.id LEFT JOIN ente ON lab.ente= ente.id
          WHERE cert.id= ←cert`, {cert: idCertificato}) 

      var fiscaleCertificato= await this.axios.query("fetchRow", 
         `SELECT id, numero, mezzo, totale
          FROM fiscale 
          WHERE id>0 AND documento= 1 AND area= 1 AND areaid= ←cert`, {cert: idCertificato})

      var percorsoCertificato= this.calcolaPercorsoCertificato(idCertificato, "pubblico")
      var percorsoFattura= this.calcolaPercorsoFattura(fiscaleCertificato.result.id, "pubblico")

      fiscaleCertificato.result.linkFattura= "https://greenlabs.eflm.eu/dwn/"+this.aes.encode({ name: "Invoice-"+fiscaleCertificato.result.numero+".pdf", url: percorsoFattura })
      certificato.result.linkCertificato= "https://greenlabs.eflm.eu/dwn/"+this.aes.encode({ name: certificato.result.laboratorio_nome.replace(" ", "_").replace("/", "-")+"_Certificate.pdf", url: percorsoCertificato })

      var emailOggetto= "", emailSezione= ""
      switch(fiscaleCertificato.result.mezzo){
         case 202:
            emailOggetto= "EFLM Green Labs - Application Completed"
            emailSezione= "pagamentobonifico-completatato"
         break;
         case 203:
            emailOggetto= "EFLM Green Labs - Payment Completed"
            emailSezione= "pagamentocarta-completatato"
         break;
      }

      var emailBody= await this.teras.renderVue(Email, { fiscale: fiscaleCertificato.result, ente: certificato.result, sezione: emailSezione })
      await this.axios.email({ to: certificato.result.responsabile_email+"::eflm@eflm.eu::mmm@terasmm.it"/*"mattia@terasmm.it"*/, from: "eflm@eflm.eu", oggetto: emailOggetto, body: emailBody })
   }

   async elencoLaboratoriCertificati(){
      var laboratoriCertificati= await this.axios.query("FetchAll", 
         `SELECT cert.id AS certificato_id, lab.nome AS laboratorio_nome, ente.id AS ente_id, ente.nome AS ente_nome, CONCAT(lab.indirizzo, " - ", lab.citta, " (", lab.cap, ") ", naz.nomeeng) AS laboratorio_indirizzo, 
            naz.iso AS nazione_iso2, naz.nomeeng AS nazione_nome, DATE_FORMAT(cert.emessoil, "%Y-%m-%d") AS certificato_immissionedata, cert.scadenza AS certificato_scadenza
          FROM entelaboratorio_certificato AS cert LEFT JOIN entelaboratorio AS lab ON cert.entelaboratorio= lab.id LEFT JOIN ente ON ente.id= lab.ente LEFT JOIN codicefiscale.nazione AS naz ON lab.nazione= naz.iso
          WHERE cert.id>0 AND cert.scadenza>DATE(NOW())`, {})

      return laboratoriCertificati.result
   }
}

export default laboratorio