import basic from "@/models/basic"
import dbItem  from "@/teras/dbItem"
import convert from 'xml-js'

class fiscale extends basic{
   constructor (args) {
      super(args)

      this.profile = new dbItem({table: "fiscale"})

      return this
   }

   async new(tipo){
      await super.new()

      switch(tipo){
         case "fattura-certificato":
            this.profile.row.documento= 1
            this.profile.row.area= 1
            this.profile.row.fatturarea= 1
         break;
      }
      this.profile.row.step= 1
   }
   async load(id){
      await super.load(id)
   }
   async loadEncode(id){
      await super.loadEncode(id)
   }

   async save(){
      await super.save()
   }

   async generaFattura(idFattura){
      if(idFattura!=undefined){
         await this.load(idFattura)
      }

      var laboratorio= await this.axios.query("fetchRow", 
         `SELECT lab.id AS laboratorio_nome, lab.nome AS laboratorio_nome
          FROM entelaboratorio_certificato AS lab_cert LEFT JOIN entelaboratorio AS lab ON lab_cert.entelaboratorio= lab.id
          WHERE lab_cert.id= ←cert`, {cert: this.profile.row.areaid})

      var xml = convert.js2xml({ fiscale: this.teras.objmerge(this.profile.row, { datadocumento_display: this.luxon.fromSQL(this.profile.row.datadocumento).toFormat("dd MMMM yyyy"), mezzo_display: this.cercaElementoSelect(this.profile.row.mezzo, this._mezziPagamento, "id")[0].label_upper, datapagamento_display: this.luxon.fromSQL(this.profile.row.datapagamento).toFormat("dd MMMM yyyy") }), laboratorio: { nome: laboratorio.result.laboratorio_nome } }, this.teras.defaultConvertOpt)

      var response= await this.axios.post(this.teras.apiUrl+"vue/pdf", {
         fopVersion: "2.8",
         pdf: this.calcolaPercorsoFattura(this.profile.row.id, "locale"),
         xsl: "/www/greenlabs.eflm.eu/template/fattura.xsl",
         xml: xml
      })
      console.log(response)
      if(!response.data.data.esitogenerazione){
         var testoEmailErrore= "An error is occured while generating the invoice <strong>"+this.profile.row.numero+"</strong> ("+idFattura+")<br><ul>"
         for(var log of response.data.data.log){
            testoEmailErrore+= "<li>"+log+"</li>"
         }
         testoEmailErrore+= "</ul>"
         this.axios.email({ to: "mmm@terasmm.it", from: "eflm@eflm.eu", oggetto: "[EFLM Green Labs] Error genereting invoice PDF", body: testoEmailErrore })
         return { esito: false }
      }

      return { esito: true, url: this.calcolaPercorsoFattura(this.profile.row.id, "pubblico") }
   }
}

export default fiscale