import basic from "@/models/basic"
import dbItem  from "@/teras/dbItem"
import convert from 'xml-js'
import Email from "@/components/Email"

class checklistLaboratorio extends basic{
   constructor (args) {
      super(args)
        
      this.profile = new dbItem({table: "entelaboratorio_checklist"})
      this._settori= []
      this.laboratori= []

      return this
   }

   //indexCategoria e indexDomanda cominciano dallo 0
   rispostaDBKey(indexCategoria, indexDomanda, checklist){
      var numeroDomandeCategoriePrecedenti= 0
      if(indexCategoria>0){
         for(var i=0; i<indexCategoria; i++){
            numeroDomandeCategoriePrecedenti+= checklist.categorie.categoria[i].domanda.length
         }
      }
      return "r"+(String(indexDomanda+numeroDomandeCategoriePrecedenti+1001)).substring(1,4)
   }
   getMassimoPunteggioDomanda(domanda){
      var punteggioMassimo= 0
      for(var risposta of domanda.risposta){
         if(parseInt(risposta._attributes.punteggio)>punteggioMassimo){
            punteggioMassimo= parseInt(risposta._attributes.punteggio)
         }
      }
      console.log(punteggioMassimo)
      return punteggioMassimo
   }
   resetCampiRisposteNonDate(){
      for(var field in this.profile.row){
         if(this.profile.row[field]==0 && (field.startsWith("r0") || field.startsWith("r1"))){
            this.profile.row[field]= ""
         }
      }
   }
   resetCampiRisposteDate(){
      for(var field in this.profile.row){
         if(typeof this.profile.row[field]=="number" && (field.startsWith("r0") || field.startsWith("r1"))){
            this.profile.row[field]= this.profile.row[field].toString()
         }
      }
   }

   async new(){
      await super.new()
   }
   async load(id){
      await super.load(id)
   }
   async loadEncode(id){
      await super.loadEncode(id)
   }
   async save(checklist){
      if(this.profile.row.id==""){
         this.profile.row.creatoil= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      }
      
      var bckPrivacy= []
      if(checklist!=undefined){
         console.log(this.profile.row)
         for(var indexCategoria in checklist.categorie.categoria){
            bckPrivacy.push(this.profile.row["area"+(parseInt(indexCategoria)+1)+"_privacy"])
            delete this.profile.row["area"+(parseInt(indexCategoria)+1)+"_privacy"]
         }
         console.log(bckPrivacy)
      }
      
      await super.save()

      if(checklist!=undefined){
         //Ripristino i valori della privacy nel caso voglia continuare a compilare la checklist (se non vengono ripristinati i valori dovrà togliere la spunta e rimetterla anche se già spuntata)
         for(var index= 1; index<=bckPrivacy.length; index++){
            this.profile.row["area"+index+"_privacy"]= bckPrivacy[index-1]
         }
         console.log(this.profile.row)
      }
   }

   valuta(checklist){
      var rispostePunteggio= {}
      var areePunteggio= []
      for(var indexCategoria= 0; indexCategoria<checklist.categorie.categoria.length; indexCategoria++){
         var categoria= checklist.categorie.categoria[indexCategoria]
         var areaPunteggio= { dbKey: "area"+(indexCategoria+1)+"_punteggio", total: 0, obtain: 0, numdomande: categoria.domanda.length, percentuale: 0, percentualesuperamento: parseFloat(categoria._attributes.minscore) }

         for(var indexDomanda= 0; indexDomanda<categoria.domanda.length; indexDomanda++){
            var domanda= categoria.domanda[indexDomanda]
            var rispostaData= domanda.risposta[this.profile.row[this.rispostaDBKey(indexCategoria, indexDomanda, checklist)]-1]
            var punteggioRisposta= parseInt(rispostaData._attributes.punteggio)
            rispostePunteggio[this.rispostaDBKey(indexCategoria, indexDomanda, checklist)]= punteggioRisposta
            //Se il punteggio della risposta è -99 devo saltare la valutazione di questa domanda
            if(punteggioRisposta==-99){
               continue;
            }
            areaPunteggio.obtain+= punteggioRisposta
            areaPunteggio.total+= this.getMassimoPunteggioDomanda(domanda)
         }
         areaPunteggio.percentuale= 100-((areaPunteggio.total-areaPunteggio.obtain)/areaPunteggio.total*100)

         areePunteggio.push(areaPunteggio)
      }
      
      console.log(areePunteggio)
      console.log(rispostePunteggio)

      return areePunteggio
   }
   async invia(checklist){
      console.log(checklist)

      var checklistItem= new dbItem({table: "_checklist"})
      await checklistItem.load(this.profile.row.checklist)

      var valutazioni= this.valuta(checklist)
      switch(checklistItem.row.valutazione_tipo){
         //La checklist è da valutare per intero
         case 3:
            var totalScore= 0, totalScoreObtain= 0
            for(var valutazione of valutazioni){
               totalScore+= valutazione.total
               totalScoreObtain+= valutazione.obtain
            }
            this.profile.row.punteggio= 100-((totalScore-totalScoreObtain)/totalScore*100)
            this.profile.row.esito= this.profile.row.punteggio>=checklistItem.row.valutazione_minimia ? 1 : -1
         break;
         //La checklist è da valutare per categorie
         case 6:
            var categorieSuperate= 0
            for(var valutazione of valutazioni){
               this.profile.row[valutazione.dbKey]= valutazione.percentuale
               if(valutazione.percentuale>=valutazione.percentualesuperamento){
                  categorieSuperate++
               }
            }
            this.profile.row.esito= categorieSuperate==valutazioni.length ? 1 : -1
         break;
      }
      this.profile.row.bozza= 0
      this.profile.row.inviatoil= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")

      console.log(this.profile.row)
      await this.save(checklist)
      /*if(process.env.NODE_ENV == "development" ) {
         return;
      }*/

      await this.inviaMail(checklist, checklistItem)
   }
   async inviaMail(checklist, checklistItem){
      var laboratorioItem= new dbItem({table: "entelaboratorio"})
      await laboratorioItem.load(this.profile.row.entelaboratorio)

      var enteItem= new dbItem({table: "ente"})
      await enteItem.load(laboratorioItem.row.ente)

      var destinatariEmail= ["eflm@eflm.eu", "mmm@terasmm.it"]
      if(![1, 2].includes(laboratorioItem.row.ente)){
         //Inludo il controllore nei destinatari solo se NON sto inviando la checklist per gli istituti di test TerAs(1) ed EFLM(2)
         destinatariEmail.push("ozben@akdeniz.edu.tr")
      }

      if(checklistItem.row.tipo==3 && this.profile.row.esito==1){
         var sezione= checklistItem.row.valutazione_tipo==6 ? "nuovocertificato-percentuale-approvazione" : "nuovocertificato-fisso-approvazione"
         var body= await this.teras.renderVue(Email, { sezione: sezione, ente: enteItem.row, laboratorio: laboratorioItem.row, checklist: checklist, checklistInviata: this.teras.objmerge(this.profile.row, { linkAccettazione: "https://greenlabs.eflm.eu/eflm-taskforce/endorse-application/"+this.aes.encode(this.profile.row.id) }) })
         await this.axios.email({ to: destinatariEmail.join("::")/*"mattia@terasmm.it"*/, from: "eflm@eflm.eu", oggetto: `New application TO ENDORSE for the EFLM certificate: “Green & Sustainable Laboratory“`, body: body })
      }
   }
   async accetta(){
      var laboratorioItem= new dbItem({table: "entelaboratorio"})
      await laboratorioItem.load(this.profile.row.entelaboratorio)

      var enteItem= new dbItem({table: "ente"})
      await enteItem.load(laboratorioItem.row.ente)
   
      var body= await this.teras.renderVue(Email, { sezione: "nuovocertificato-accettato", ente: enteItem.row, laboratorio: this.teras.objmerge(laboratorioItem.row, { linkPagamento: "https://greenlabs.eflm.eu/reserved-area/institution/laboratory/"+this.aes.encode(laboratorioItem.row.id)+"/application-payment/"+this.aes.encode(this.profile.row.id) }) })
      await this.axios.email({ to: enteItem.row.responsabile_email+"::eflm@eflm.eu::mmm@terasmm.it"/*"mattia@terasmm.it"*/, from: "eflm@eflm.eu", oggetto: `Outcome of the evaluation for the EFLM certificate: “Green & Sustainable Laboratory“`, body: body })
      
      this.profile.row.accettatoil= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      await this.save()
   }

   async getCheckListDaCompilare(isRinnovo){
      var tipoChecklist= isRinnovo ? 6 : 3
      var checkList= await this.axios.query("fetchRow", `SELECT id FROM _checklist WHERE id>0 AND attivo= 1 AND tipo= ←tipo`, { tipo: tipoChecklist })
      if(checkList.result.id==undefined){
         if(!isRinnovo){
            this.axios.email({to: "mattia@terasmm.it", from: "greenlabs@eflm.eu", oggetto: "[EFLM Green Labs] Nessuna Checklist NUOVA CERTIFICAZIONE trovata", body: `Attenzione: non è stata trovata nessuna checklist per ottenere una nuova certificazione <strong>EFLM Green Labs</strong> per il progetto <a href="https://greenlabs.eflm.eu" target="_blank">EFLM GreenLabs</a>, controllare!!!`})
         } else{
            this.$axios.email({to: "mattia@terasmm.it", from: "greenlabs@eflm.eu", oggetto: "[EFLM Green Labs] Nessuna Checklist RINNOVO CERTIFICAZIONE trovata", body: `Attenzione: non è stata trovata nessuna checklist per rinnovare il certificato <strong>EFLM Green Labs</strong> per il progetto <a href="https://greenlabs.eflm.eu" target="_blank">EFLM GreenLabs</a>, controllare!!!`})
         }
      }
      /*if(process.env.NODE_ENV == "development" ) {
         return  { id: 5 }
      }*/
      return checkList.result
   }
   /*async getCheckListNuovaCertificazione(){
      var checkList= await this.axios.query("fetchRow", `SELECT id FROM _checklist WHERE id>0 AND attivo= 1 AND tipo= 3`, {})
      return checkList.result
   }
   async getCheckListRinnovoCertificazione(){
      var checkList= await this.axios.query("fetchRow", `SELECT id FROM _checklist WHERE id>0 AND attivo= 1 AND tipo= 6`, {})
      return checkList.result
   }*/
   async loadChecklistDomande(nomeFileDomande){
      var response= await this.axios.post(this.teras.apiUrl+"vue/filecontent", {filepath: "/www/greenlabs.eflm.eu/checklists/"+nomeFileDomande})
      console.log(response)
      var checklistJSON= convert.xml2js(response.data.data.content, {compact: true, ignoreComment: true, spaces: 3})

      if(checklistJSON.categorie.categoria.length>4){
         //Ho una checklist con più di 4 categorie ma nel DB c'è spazio solo per 4 categorie nel campo note
         this.axios.email({to: "mattia@terasmm.it", from: "greenlabs@eflm.eu", oggetto: "[EFLM Green Labs] Troppe categoria nella checklist", body: `Attenzione: nella checklist `+nomeFileDomande+` sono presenti più di 4 categorie, attualmente il DB gestisce le note per 4 aree (area1_note, area2_note, ecc...).<br />Aggiungere altri campi per le aree in eccedenza!!!`})
         //this.$router.go(-1)
         return "";
      }
      
      var numeroDomande= 0
      for(var categoria of checklistJSON.categorie.categoria){
         numeroDomande+= categoria.domanda.length
      }
      if(numeroDomande>150){
         //Ho una checklist con più di 150 domande ma nel DB c'è spazio solo per 150 campi per salvare le risposte
         this.axios.email({to: "mattia@terasmm.it", from: "greenlabs@eflm.eu", oggetto: "[EFLM Green Labs] Troppe domande nella checklist", body: `Attenzione: nella checklist `+nomeFileDomande+` sono presenti più di 150 domande, attualmente il DB gestisce le risposte per 150 domande (r001, r002, r003, ecc...).<br />Aggiungere altri campi per le domande in eccedenza!!!`})
         //this.$router.go(-1)
         return "";
      }
      console.log(numeroDomande)
      return checklistJSON
   }

   async salvaBozza(checklist){
      this.profile.row.bozza= 1
      await this.save(checklist)
   }

   async haCertificato(idChecklist){
      if(idChecklist!=undefined){
         await this.load(idChecklist)
      }

      var certificato= await this.axios.query("fetchRow", 
         `SELECT id 
         FROM entelaboratorio_certificato 
         WHERE id>0 AND entelaboratorio_checklist= ←chkl`, {chkl: this.profile.row.id})
      return certificato.result.id!=undefined
   }

}

export default checklistLaboratorio