import basic from "@/models/basic"
import dbItem  from "@/teras/dbItem"
import Email from "@/components/Email"
/*import laboratorioModel from "./laboratorio"
import laboratorioChecklist from "./checklistLaboratorio"*/

class ente extends basic{
   constructor (args) {
      super(args)
        
      this.profile = new dbItem({table: "ente"})
      this._settori= []
      this.laboratori= []

      return this
   }
   async checkCampoUnivoco(nomeCampo){
      var condId= this.profile.row.id=="" ? "" : " AND id<>←id"
      var checkCampo= await this.axios.query("fetchRow", 
         `SELECT id FROM ente WHERE id>0 AND ←campo= →valcampo`+condId, {id: this.profile.row.id, campo: nomeCampo, valcampo: this.profile.row[nomeCampo]})
      return checkCampo.result.id==undefined
   }

   async new(){
      await super.new()
   }
   async load(id){
      await super.load(id)
      await this.elencoLaboratori()
   }
   async loadEncode(id){
      await super.loadEncode(id)
      await this.elencoLaboratori()
   }

   async elencoLaboratori(){
      await this.axios.query("fetchAll", 
         `SELECT lab.id, lab.nome, lab.dipartimento, labtipo.label_en AS tipo_display, CONCAT(direttore_nome, " ", direttore_cognome) AS direttore_nominativo,
            lab_lastcert.id AS certificato_id, lab_lastcheck.id AS checklist_id, lab_lastcheck.inviatoil AS checklist_invio, CASE 
               WHEN lab_lastcert.scadenza IS NULL AND lab_lastcheck.id IS NULL THEN "never"
               WHEN lab_lastcert.scadenza IS NULL AND lab_lastcheck.esito=0 AND lab_lastcheck.bozza= 1 THEN "draft"
               WHEN lab_lastcert.scadenza IS NULL AND lab_lastcheck.esito=-1 AND _checklist.tipo=3 THEN "not-passed"
               WHEN lab_lastcert.scadenza IS NULL AND lab_lastcheck.esito=-1 AND _checklist.tipo=6 THEN "not-passed-renewal"
               WHEN lab_lastcert.scadenza IS NULL AND lab_lastcheck.esito=1 AND lab_lastcheck.accettatoil="0000-00-00 00:00:00" THEN "to-be-accepted"
               WHEN lab_lastcert.scadenza IS NULL AND lab_lastcheck.esito=1 AND lab_lastcheck.accettatoil<>"0000-00-00 00:00:00" THEN "to-pay"
               WHEN lab_lastcert.scadenza>=DATE(NOW()) THEN "active"
               ELSE "expired"
            END AS certificato_stato
         FROM entelaboratorio AS lab LEFT JOIN _entelaboratorio_tipo AS labtipo ON lab.tipo= labtipo.id 
            LEFT JOIN entelaboratorio_ultimocertificato AS lab_lastcert ON lab.id= lab_lastcert.entelaboratorio LEFT JOIN entelaboratorio_ultimachecklist AS lab_lastcheck ON lab.id= lab_lastcheck.entelaboratorio
            LEFT JOIN _checklist ON lab_lastcheck.checklist= _checklist.id
         WHERE lab.id>0 AND lab.ente= ←osp
         ORDER BY lab.creatoil`, {osp: this.profile.row.id}, this, "laboratori")

      /*for(var laboratorio of this.laboratori){
         var laboratorioItem= new laboratorioModel({ axios: this.axios, teras: this.teras })
         var laboratorioChecklistItem= new laboratorioChecklist({ axios: this.axios, teras: this.teras })
         laboratorio.haChecklistNonSuperata= await laboratorioItem.haChecklistNonSuperata(laboratorio.id)
         laboratorio.haChecklistInBozza= await laboratorioItem.haChecklistInBozza((await laboratorioChecklistItem.getCheckListNuovaCertificazione()).id, laboratorio.id)
      }*/
   }

   async save(){
      if(this.profile.row.id==""){
         this.profile.row.creatoil= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      }
      await super.save()
   }

   richiediResetPassword(email){
      return new Promise(async (resolve, reject) => {
         try{
            var checkMail= await this.axios.query("fetchRow", 
            `SELECT id, responsabile_nome, responsabile_cognome, responsabile_email FROM ente WHERE id>0 AND responsabile_email= →email`, {email: email})
            if(checkMail.result.id==undefined){
               this.teras.event.emit("alert", "Error", "The email you have inserted is not present in our system", "error", (action) => {
                  console.log(action)
               })
               resolve(false)
               return
            }
            
            var now= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
            var ente= checkMail.result
            ente.linkReset= "https://greenlabs.eflm.eu/reset-password/"+this.aes.encode({ente: ente.id, data: now})
            var emailBody= await this.teras.renderVue(Email, { ente: ente, sezione: "reset-password" })

            this.axios.email({ to: ente.responsabile_email/*+"::mattia@terasmm.it"*/, from: "eflm@eflm.eu", oggetto: "[EFLM Green Labs] Reset Password", body: emailBody, css: "https://greenlabs.eflm.eu/css/email.css"}).then(async (data) => {
               console.log(data)
               await this.profile.load(ente.id)
               this.profile.row.responsabile_passwd_ultimoreset= now
               await this.profile.save()
               resolve(true)
            }).catch((error) => {
               console.log("ERRORE NELL'INVIO DELLA EMAIL")
               console.log(error)
               this.teras.event.emit("message", "Error sending the email to reset the password", "error")
               resolve(false)
            })
         } catch(error){
            console.error(error)
            resolve(false)
         }
      })
   }
   async login(email, password){
      var checkLogin= await this.axios.query("fetchRow", 
         `SELECT id FROM ente WHERE id>0 AND responsabile_email= →email AND responsabile_passwd= →password`, {email: email, password: password})
      if(checkLogin.result.id==undefined){
         return false;
      }
      await this.load(checkLogin.result.id)
      this.teras.check.auth= true
      this.storage.save("utente", this.profile.row)
      return true;
   }
   logout(changeRoute){
      this.teras.check.auth= false
      this.storage.remove("utente")
      if(changeRoute==undefined || changeRoute){
         this.teras.event.emit("routerReplace", "/")
      }
      this.profile = new dbItem({table: "ente"})
   }

   async checkEmail(){
      return await this.checkCampoUnivoco("responsabile_email")
   }
   async checkPartitaIVA(){
      return await this.checkCampoUnivoco("piva")
   }

   async calcolaPrezzoDaPagareCertificato(){
      var infoLaboratori= await this.axios.query("fetchRow", 
         `SELECT COUNT(*) AS numlaboratori 
          FROM entelaboratorio 
          WHERE id>0 AND ente= ←ente AND id IN (SELECT entelaboratorio FROM entelaboratorio_certificato WHERE id>0)`, { ente: this.profile.row.id })
      
      var importoDaPagare= 0
      switch(true){
         case infoLaboratori.result.numlaboratori<=1:
            importoDaPagare= 500
         break;
         case infoLaboratori.result.numlaboratori>=2 && infoLaboratori.result.numlaboratori<=10:
            importoDaPagare= 400
         break;
         case infoLaboratori.result.numlaboratori>10:
            importoDaPagare= 300
         break;
      }
      console.log(importoDaPagare)
      return importoDaPagare
   }
}

export default ente