const EventEmitter = require("events").EventEmitter

class basic{

   constructor (args) {
      this.event = new EventEmitter()
      this.teras= args.teras

      this.luxon = args.luxon
      this.aes= args.aes
      this.axios= args.axios
      this.storage= args.storage
      this.router= args.router
      
      this.profile= {}
      this.oldrow= {}

      this._nazioni= []
      this._mezziPagamento= []
      
      return this
   }

   async new(){
      await this.profile.new()
      this.oldrow= this.teras.copiaOggetto(this.profile.row)
   }
   async load(id){
      await this.profile.load(id)
      this.oldrow= this.teras.copiaOggetto(this.profile.row)
   }

   async loadEncode(id){
      await this.profile.loadEncode(id)
      this.oldrow= this.teras.copiaOggetto(this.profile.row)
   }

   async save(){
      await this.profile.save()
      //await this.registraLogCambiamento()
   }

   cercaElementoSelect(query, arrayToSearch, propToSearch){
      var arrayToPush= []
      if (query !== "") {
         arrayToSearch.forEach(element => {
            if(element[propToSearch].toString().toLowerCase().includes(query.toString().toLowerCase())){
               arrayToPush.push(element)
            }
         })
      } 
      return arrayToPush
   }

   getArrayOfProp(array, propName){
      var myArrayOfProp= []
      array.forEach(element => {
         myArrayOfProp.push(element[propName])
      })
      return myArrayOfProp
   }

   calcolaPercorsoCertificato(idCertificato, tipo){
      var url= "/www/greenlabs.eflm.eu/certificates/"+this.aes.encode(idCertificato)+".pdf"
      if(tipo=="pubblico"){
         url= url.replace("/www/", "https://")
      }
      return url
   }
   calcolaPercorsoFattura(idFiscale, tipo){
      var url= "/www/greenlabs.eflm.eu/fiscale/fatture/"+this.aes.encode(idFiscale)+".pdf"
      if(tipo=="pubblico"){
         url= url.replace("/www/", "https://")
      }
      return url
   }

}
export default basic