<template>
   <table ref="body" align="center" style="width: 800px; margin: 40px auto; background: #FFFFFF; border: 1px solid #353535; border-spacing: 0; border-collapse: collapse;">
      <tr>
         <td>
            <table width="800" cellpadding="0" cellspacing="0" border="0">
               <tr>
                  <td style="text-align: center; padding: 24px 16px;"><img :src="'https://greenlabs.eflm.eu/images/logo.png'" width="184" height="120" /></td>
               </tr>
               <tr>
                  <td style="padding: 16px 24px">
                     <div v-if="['reset-password'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #a7d05a;">Reset Password Request</h3>
                        Dear {{ente.responsabile_nome}} {{ente.responsabile_cognome}}<br /><br />
                        We have received a request to reset the password of your EFLM Green Labs account.<br />

                        <table cellmargin="0" cellspacing="8" width="800" border="0" align="center" style="border: none; padding: 24px;">
                           <tr>
                              <td width="40%"><div style="text-align: center;">YOUR RESET LINK</div></td>
                              <td width="60%"><a :href="ente.linkReset" style="border: 0">Click here to reset your password</a></td>    
                           </tr>
                        </table>

                        We remind you that for security reasons the link is valid only for <strong>24 hours</strong>, after that you need to request a <a href="https://greenlabs.eflm.eu/reset-password" target="_blank">new reset</a> of the password.<br /><br />
                        If you have any problem please <a href="mailto:eflm@eflm.eu">contact the EFLM office</a>
                     </div>

                     <div v-else-if="['ente-registrazione'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #a7d05a;">New Institution Registered</h3>
                        Dear EFLM Office,<br />
                        <p>A new istitution has registered in the <a href="https://greenlabs.eflm.eu" target="_blank">Green Labs webiste</a>.</p>
                        <p>The insitution name is <strong>{{ ente.nome }}</strong> ({{ ente.nazione }}) - Contact: <a :href="'mailto:'+ente.responsabile_email">{{ ente.responsabile_nome }} {{ ente.responsabile_cognome }}</a>.</p>
                     </div>
                     
                     <div v-else-if="['nuovocertificato-fisso-approvazione', 'nuovocertificato-percentuale-approvazione'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #a7d05a;">New Application to evaluate</h3>

                        <p>
                           Dear TF-GSL Member,<br />
                           The Institution <strong>{{ ente.nome }}</strong> ({{ ente.citta }}, {{ ente.nazione}}) has submitted an application to certify the laboratory <strong>{{ laboratorio.nome }}</strong>  ({{ laboratorio.citta }}, {{ laboratorio.nazione}}).
                        </p>
                        <p>
                           Below the result obtained by the laboratory and the photos submitted as a proof of evidence.<br />
                           <strong>IMPORTANT:</strong> please scroll down to endorse the application and permit the closing of the application process.
                        </p>
                        <p>
                           In case you need to contact the applicant, these are the contacts: {{ ente.responsabile_nome }} {{ ente.responsabile_cognome }}<span v-if="ente.responsabile_email!=''"> – <a :href="'mailto:'+ente.responsabile_email">{{ ente.responsabile_email }}</a></span> (Lab. Resp. {{ laboratorio.direttore_cognome }} {{ laboratorio.direttore_nome }}<span v-if="laboratorio.direttore_email!=''"> – <a :href="'mailto:'+laboratorio.direttore_email">{{ laboratorio.direttore_email }}</a></span>)
                        </p>
                        <div v-if="['nuovocertificato-fisso-approvazione'].includes(sezione)">
                           <strong>Result: {{ checklistInviata.punteggio }}</strong>
                        </div>
                        <div v-else-if="['nuovocertificato-percentuale-approvazione'].includes(sezione)">
                           <h6 style="font-size: 1.8rem; font-size: 26px; line-height: 130%; margin-block-start: 0; margin-block-end: 0; color: #5e788c"><strong style="text-decoration: underline;">Result</strong></h6>
                           <table cellpadding="0" cellspacing="0" border="0" width="800" v-for="categoria, index in checklist.categorie.categoria" :key="index">
                              <tr>
                                 <td style="padding-bottom: 8px;">
                                    <strong style="color: #a7d05a">CHECKLIST - Section {{ categoria._attributes.label }}</strong><br />
                                    Score: <strong>{{ checklistInviata["area"+(index+1)+"_punteggio"] }} %</strong>
                                 </td>
                              </tr>
                              <tr v-if="checklistInviata['area'+(index+1)+'_note']!=''">
                                 <td style="padding-bottom: 8px;">
                                    <strong>Additional notes from the applicant:</strong> {{ checklistInviata['area'+(index+1)+'_note'] }}
                                 </td>
                              </tr>
                              <tr>
                                 <td style="padding-bottom: 24px;">
                                    <em>The below photo represents the submitted proof of evidence that the laboratory complies to at least one of the checklist items where it was answered “Always OR already completed”. The photo should also clearly indicate that it is from the laboratory environment.</em><br />
                                    <table cellpadding="0" cellspacing="0" border="0" align="center" width="800" v-for="allegato in JSON.parse(checklistInviata['area'+(index+1)+'_allegati'])">
                                       <tr>
                                          <td style="text-align: center; padding-bottom: 4px;">
                                             <img :src="'https://greenlabs.eflm.eu/upload/'+allegato.url" width="600" border="0" style="display: block; max-width: 100%; min-width: 600px; width: 100%;">
                                          </td>
                                       </tr>
                                       <tr>
                                          <td style="padding-bottom: 10px;">
                                             <strong>Description of the photo:</strong> {{ checklistInviata["area"+(index+1)+"_allegatidescrizione"] }}
                                          </td>
                                       </tr>
                                    </table>
                                 </td>
                              </tr>
                              
                           </table>
                        </div>
                        
                        <table cellmargin="0" cellspacing="0" width="800" border="0" align="center" style="border: none;">
                           <tr> 
                              <td style="padding-top: 24px; padding-bottom: 16px;">
                                 <div style="text-align: center;">
                                    <a :href="checklistInviata.linkAccettazione" target="_blank" style="margin: 0; padding: 0;">
                                       <table role="presentation" border="0" cellspacing="0" cellpadding="0" width="auto" align="center"> 
                                          <tbody> 
                                             <tr> 
                                                <td bgcolor="#5e788c" style="border-color: #5e788c; background-color: #5e788c; padding: 12px 24px; color: #FFFFFF; display: inline-block; text-decoration: none; margin: 0 auto; font-size:20px; line-height: 1.25em; border-radius: 4px; border-width: 1px; border-style: solid">
                                                   <a :href="checklistInviata.linkAccettazione" target="_blank" style="color: #FFFFFF;">ENDORSE APPLICATION</a>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </a>
                                 </div>
                              </td>    
                           </tr>
                        </table>

                        <p>
                           Thanks for your involvement.<br />
                           The EFLM Office
                        </p>
                     </div>
                    
                     <div v-else-if="['nuovocertificato-accettato'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #a7d05a;">Application accepted</h3>

                        <p>
                           To: <strong>{{ ente.responsabile_nome }} {{ ente.responsabile_cognome }}</strong>
                        </p>
                        <p>
                           Congratulations! The laboratory <strong>{{ laboratorio.nome }}</strong> is considered by the European Federation of Clinical Chemistry and Laboratory Medicine as a “Green & Sustainable Laboratory”.
                        </p>
                        <p>
                           Once the payment is made, the related EFLM certificate will be sent together with the invoice of the paid amount. 
                        </p>
                        <p>
                           The certificate will be valid for <strong>two years</strong> from the date of issuing.
                        </p>
                        
                        <table cellmargin="0" cellspacing="0" width="800" border="0" align="center" style="border: none;">
                           <tr> 
                              <td style="padding-top: 24px; padding-bottom: 16px;">
                                 <div style="text-align: center;">
                                    <a :href="laboratorio.linkPagamento" target="_blank" style="margin: 0; padding: 0;">
                                       <table role="presentation" border="0" cellspacing="0" cellpadding="0" width="auto" align="center"> 
                                          <tbody> 
                                             <tr> 
                                                <td bgcolor="#5e788c" style="border-color: #5e788c; background-color: #5e788c; padding: 12px 24px; color: #FFFFFF; display: inline-block; text-decoration: none; margin: 0 auto; font-size:20px; line-height: 1.25em; border-radius: 4px; border-width: 1px; border-style: solid">
                                                   <a :href="laboratorio.linkPagamento" target="_blank" style="color: #FFFFFF; text-decoration: underline;">PAY CERTIFICATE</a>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </a>
                                 </div>
                              </td>    
                           </tr>
                        </table>

                        <p>
                           Kind regards<br />
                           The EFLM Office
                        </p>
                     </div>

                     <div v-else-if="['pagamentobonifico-completatato'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #a7d05a;">Application Completed</h3>

                        <p>
                           To: <strong>{{ ente.responsabile_nome }} {{ ente.responsabile_cognome }}</strong>
                        </p>
                        <p>
                           The EFLM Green & Sustainable Labs certificate’s application for the laboratory <strong>{{ ente.laboratorio_nome }}</strong> is completed.<br />
                           You selected <strong>Bank Transfer</strong> as method of payment, the related bank details are indicated here below:
                        </p>
                        <p>
                           Account holder: <strong>EFLM</strong><br />
                           IBAN: <strong>BE08 7370 57944813</strong><br />
                           BIC: <strong>KREDBEBB</strong><br />
                           Account nr: <strong>57944813</strong><br />
                           Bank: <strong>KBC Brussels</strong><br />
                           Bank address: <strong>RD-PT Robert Schuman 4, 1040 Bruxelles, Belgium</strong><br />
                           EFLM  Legal Address: <strong>Egmontstraat 11, 1000 Bruxelles, Belgium</strong><br />
                        </p>
                        <p style="text-align: center">
                           The amount to pay is <strong style="font-size: 2.0rem; color: #5e778b">{{ $teras.visualizzaImporto(fiscale.totale) }} €</strong>
                        </p>
                        
                        <p style="padding-top: 24px">
                           <strong>IMPORTANT:</strong> please note that the payment must be received within 20 days, failing that, the certificate will be considered as cancelled.
                        </p>
                        <p>
                           Below you can find the invoice and the certificate:<br />
                           <a :href="fiscale.linkFattura" target="_blank">Invoice {{ fiscale.numero }}</a><br />
                           <a :href="ente.linkCertificato" target="_blank">Certificate</a>
                        </p>
                        
                        <p>
                           Kind regards<br />
                           The EFLM Office
                        </p>
                     </div>
                     <div v-else-if="['pagamentocarta-completatato'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #a7d05a;">Application Paid</h3>

                        <p>
                           To: <strong>{{ ente.responsabile_nome }} {{ ente.responsabile_cognome }}</strong>
                        </p>
                        <p>
                           The EFLM Green & Sustainable Labs certificate’s application for the laboratory <strong>{{ ente.laboratorio_nome }}</strong> is completed.<br />
                           You selected <strong>Credit Card</strong> as method of payment, the payment was successful. 
                        </p>
                        <p>
                           Below you can find the invoice and the certificate:<br />
                           <a :href="fiscale.linkFattura" target="_blank">Invoice {{ fiscale.numero }}</a><br />
                           <a :href="ente.linkCertificato" target="_blank">Certificate</a>
                        </p>
                        
                        <p>
                           Kind regards<br />
                           The EFLM Office
                        </p>
                     </div>
                  </td>
               </tr>
               <tr> 
                  <td style="text-align: center; background: #666666; color: #FFFFFF; padding: 24px;">
                     European Federation of Clinical Chemistry and Laboratory Medicine (EFLM)<br />
                     Via Carlo Farini, 81 - 20159 Milano - Italy<br />
                     e-mail: <a href="mailto:eflm@eflm.eu" style="color: #FFFFFF; font-weight: bold;">eflm@eflm.eu</a> - website: <a href="https://www.eflm.eu" target="_blank" style="color: #FFFFFF; font-weight: bold;">www.eflm.eu</a>
                  </td>
               </tr>
            </table>
         </td>
      </tr>
   </table>
</template>

<script>
export default {
   props: {
      sezione: {
         type: String,
         required: true
      },
      ente: {
         type: Object
      },
      laboratorio: {
         type: Object
      },
      checklist: {
         type: Object
      },
      fiscale: {
         type: Object
      },
      checklistInviata: {
         type: Object
      },
   }
}
</script>