<template>
   <div v-loading.fullscreen.lock="!$teras.app.ready" :class="'device-'+$teras.app.device+' '+$route.name">
      <el-config-provider value-on-clear="">
         <site-header v-if="!['new-account', 'login', 'reset-password', 'reset-password-action'].includes($route.name)" />
         <!--<site-header />-->

         <div class="app-main row">
            <div id="main" class="col s12 yo-text-center" >
               <router-view name="main"></router-view>
            </div><!-- end main -->
         </div><!-- end container -->
         
         <!--<site-footer v-if="!['new-account', 'login', 'reset-password', 'reset-password-action'].includes($route.name)" />-->
         <site-footer />
      </el-config-provider>
   </div> <!-- end app -->
</template>
<script>
import { ElConfigProvider } from "element-plus"
import { defineAsyncComponent } from "vue"
export default {
   name: 'app',
   components: {
      ElConfigProvider,
      
      siteHeader: defineAsyncComponent(() => import("@/components/Site-header.vue")),
      siteFooter: defineAsyncComponent(() => import("@/components/Site-footer.vue")),
   },
   data() {
      return {
         //loading:{}
      }
   },
   created(){
      this.$teras.event.on("message", (messaggio, tipo) => {
         if(tipo==undefined) tipo= "info"

         this.$message[tipo](messaggio)
      })

      this.$teras.event.on("alert", (titolo, messaggio, tipo, callback) => {
         this.$alert(messaggio, titolo, {type: tipo, showClose: false}).then((action) => {
            callback(action)
         })
      })

      this.$teras.event.on('loading', (messaggio) => {
         console.log(typeof messaggio)
         messaggio = (typeof messaggio == 'string') ? messaggio : 'Caricamente in corso, non ricaricare la pagina.....'
         console.log("messaggio: "+messaggio)
         this.loading = this.$loading({lock: true, text: messaggio, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
      })
      this.$teras.event.on('loadingClose', () => {
         if(this.loading!=undefined){
            this.loading.close()
         }
      })
   }
}
</script>