import { createApp } from 'vue/dist/vue.esm-bundler'; 
import {h, reactive} from 'vue'
import App from './App.vue'


/* Inizio Router */
import { createVueRouter } from './router'
/* Fine Router */

/* Inizio ElementPlus */
import {ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import "./css/element-var.css"
//import 'element-plus/theme-chalk/index.css';


/* Fine ElementPlus */

/* Inizio Luxon */
//https://moment.github.io/luxon/#/moment
//https://moment.github.io/luxon/#/formatting?id=table-of-tokens
import { DateTime as luxon, Settings as luxonSettings } from "luxon"
luxonSettings.defaultLocale= "en"
luxonSettings.defaultZone= "Europe/Rome"
/* Fine Luxon */

/* Inizio TerAs */
import terasImp from './teras'
var teras= reactive(terasImp)
import aes from './teras/aes'
import axios from './teras/tAxios'
import storage from './teras/tStorage' 

import './css/big.css'
/* Fine TerAs */

/* Inizio TerAs-event */
teras.event.on('checkFirst', async (from, to) =>{
   await axios.query("fetchAll", 
      `SELECT id, id AS valore, label_en AS label FROM _ente_settore WHERE id>0 ORDER BY label_en`, {}, ente, "_settori")
   
   await axios.query("fetchAll", 
      `SELECT id, id AS valore, label FROM _entelaboratorio_numerotest WHERE id>0 ORDER BY peso`, {}, laboratorio, "_numerotesteffettuati")
   await axios.query("fetchAll", 
      `SELECT id, id AS valore, label_en AS label FROM _entelaboratorio_tipo WHERE id>0 ORDER BY label_en`, {}, laboratorio, "_tipi")
   
   var nazioni= await axios.query("fetchAll", 
      `SELECT id, iso AS valore, nomeeng AS label FROM codicefiscale.nazione WHERE id>0 AND iso NOT IN ("", "-") ORDER BY nomeeng`, {})
   ente._nazioni= nazioni.result
   laboratorio._nazioni= nazioni.result
   fiscale._nazioni= nazioni.result

   var mezziPagamento= await axios.query("fetchAll", 
      `SELECT id, id AS valore, nomeng AS label, UPPER(nomeng) AS label_upper FROM _mezzo WHERE id>0 ORDER BY nomeng`, {})
   ente._mezziPagamento= mezziPagamento.result
   laboratorio._mezziPagamento= mezziPagamento.result
   fiscale._mezziPagamento= mezziPagamento.result

   teras.check.first = true
   //router.replace(to.path)

   var pathToGo= to.path
   console.log("CHECK AUTH")
   if(storage.exist('utente')){
      console.log("YES, PRESENTE UTENTE IN LOCAL STORAGE....LO RICARICO DA LI..."+to.path)
      var utente = storage.load('utente')
      if(!await ente.login(utente.responsabile_email, utente.responsabile_passwd)){
         //Se le credenziali non combaciano più effettuo il logout
         ente.logout(false)
         if(to.meta.requiresAuth){
            pathToGo= "/login"
         }
      }
   } else{
      teras.check.auth = false
      if(from.path!='/login' && to.meta.requiresAuth){
         pathToGo= "/login"
      }
   }
   router.replace(pathToGo)
})
/*teras.event.on('checkAuth', async (from,to) =>{
   console.log("CHECK AUTH")
   if(storage.exist('utente')){
      console.log("YES, PRESENTE UTENTE IN LOCAL STORAGE....LO RICARICO DA LI..."+to.path)
      var utente = storage.load('utente')
      if(await ente.login(utente.responsabile_email, utente.responsabile_passwd)){
         router.replace(to.path)
      } else{
         //Se le credenziali non combaciano più effettuo il logout
         ente.logout()
         //router.replace("/login")
      }
   }else{
      teras.check.auth = false
      //teras.event.emit('errore',-1,'È necessario effettuare l\'accesso prima di poter accedere alla pagina')
      if(from.path!='/login'){
         router.replace('/login')
      }
   }
})*/
teras.event.on('appReady', function() {
   console.log("appReady appReady appReady appReady appReady appReady appReady")
   router.push(teras.app.routeInitial)
})
teras.event.on('routerReplace', function(to) {
   router.replace(to)
})

teras.event.on('setSeoInformation', async function(page){
   new Promise((resolve) => {
      teras.event.emit('removeSeoInformationFromPrevPage', resolve)
   }).then(async (value) => {
      //Imposto il meta title e description per la pagina statica recuperata
      for(var i=0; i<page.head.children.length; i++){
         if(page.head.children[i].attributes[0].nodeName=='title'){
            console.log('SEO title: ' +page.head.children[i].attributes[0].nodeValue)
            var meta = document.createElement('meta');
            meta.title= page.head.children[i].attributes[0].nodeValue
            document.getElementsByTagName('head')[0].appendChild(meta);
         }
         if(page.head.children[i].attributes[0].nodeName=='name' && page.head.children[i].attributes[0].nodeValue=='description' && 
            page.head.children[i].attributes[1]!=undefined && page.head.children[i].attributes[1].nodeName=='content'){
            console.log('SEO description: ' +page.head.children[i].attributes[1].nodeValue)
            var meta = document.createElement('meta');
            meta.name='description'
            meta.content = page.head.children[i].attributes[1].nodeValue
            document.getElementsByTagName('head')[0].appendChild(meta);
         }
      }
   })
})
teras.event.on('removeSeoInformationFromPrevPage', function(callback){
   //Salvo dentro l'array nodeToRemove i tag meta della pagina statica precedente che sono da rimuovere per inserire quelli nuovi
   var nodeToRemove= []
   for(var i=0; i<document.getElementsByTagName('head')[0].children.length; i++){
      var node= document.getElementsByTagName('head')[0].children[i]
      if(node.localName=='meta' && node.attributes[0].nodeName=='title'){
         nodeToRemove.push(node)
      }
      if(node.localName=='meta' && node.name=='description'){
         nodeToRemove.push(node)
      }
   }
   for(var i=0; i<nodeToRemove.length; i++){
      document.getElementsByTagName('head')[0].removeChild(nodeToRemove[i])
   }
   if(callback!=undefined){
      //console.log('callback')
      callback(true)
   }
})

window.addEventListener('resize',() => { 
   teras.getDevice()
   teras.event.emit('appResize')
})
window.addEventListener('scroll',() => { 
   teras.getDevice()
   teras.event.emit('appScroll')
})

/* Fine TerAs-event */

/* Inizio Vue-3 */

const app= createApp({
   /*data() {
      return vueData
   },*/
   render: () => h(App)
})

app.config.globalProperties.$teras= teras
app.config.globalProperties.$aes = aes
app.config.globalProperties.$axios = axios
app.config.globalProperties.$luxon = luxon
app.config.globalProperties.$storage = storage

const router = createVueRouter(app)
app.use(router)
app.use(ElLoading)
app.use(ElMessage)
app.use(ElMessageBox)

import checklistLaboratorioImp from '@/models/checklistLaboratorio';
var checklistLaboratorio= reactive(new checklistLaboratorioImp({aes: aes, teras: teras, axios: axios, luxon: app.config.globalProperties.$luxon, storage: storage}))

import fiscaleImp from '@/models/fiscale';
var fiscale= reactive(new fiscaleImp({aes: aes, teras: teras, axios: axios, luxon: app.config.globalProperties.$luxon, storage: storage}))

import laboratorioImp from '@/models/laboratorio';
var laboratorio= reactive(new laboratorioImp({aes: aes, teras: teras, axios: axios, luxon: app.config.globalProperties.$luxon, storage: storage}))

import enteImp from '@/models/ente';
var ente= reactive(new enteImp({aes: aes, teras: teras, axios: axios, luxon: app.config.globalProperties.$luxon, storage: storage}))

app.config.globalProperties.$checklistLaboratorio= checklistLaboratorio
app.config.globalProperties.$fiscale= fiscale
app.config.globalProperties.$laboratorio= laboratorio
app.config.globalProperties.$ente= ente

app.mount('#app')

teras.vueAppInstance= app

/* Fine Vue-3 */
